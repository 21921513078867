<template>
  <section class="section shop">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 order-2 order-lg-1">
          <Sidebar></Sidebar>
        </div>

        <div class="col-lg-9 order-1 order-lg-2 mb-6 mb-lg-0" v-if="order">
          <section class="section mb-6">
            <div class="section__header mb-4">
              <div class="d-flex align-items-center">
                <h1 class="section__title mb-0 h3">
                  {{ $t("order.order-hash") }}{{ order.order_number }}
                </h1>
              </div>
            </div>

            <div class="section__body">
              <div
                class="section__content hentry text-sm"
                v-if="order.order_date"
              >
                <!-- <p>
                  <i18n-d
                    tag="p"
                    :value="order.order_date"
                    format="short"
                  ></i18n-d>
                  / {{ order.status }}
                </p> -->
                <!-- <p>{{ order.order_date }} / {{ order.status }}</p> -->
                <p>{{ $d(order.order_date, "short") }} / {{ order.status }}</p>
                <p v-if="order.reference">
                  {{ $t("order.reference") }}
                  <br />
                  <strong class="text-lg">{{ order.reference }}</strong>
                </p>
              </div>
            </div>
          </section>

          <section class="section mb-6">
            <div class="section__header d-flex align-items-center mb-3">
              <h4 class="section__title" v-t="'order.about-order'">
                About this order
              </h4>
            </div>
            <div class="section__body box bg-light">
              <div class="box__content">
                <div class="box-inner">
                  <div class="row row--col-margin-semismall">
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.shipping-address'"
                        >
                          Shipping address
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <div v-if="order.shipping_address">
                          <div>
                            <span>{{ order.shipping_address.firstname }}</span
                            >&nbsp;<span>{{
                              order.shipping_address.lastname
                            }}</span
                            ><br />
                          </div>

                          <div v-if="order.shipping_address.street">
                            <template
                              v-for="(streetItem, i) in order.shipping_address
                                .street"
                              :key="i"
                            >
                              <span class="d-block">{{ streetItem }}</span>
                            </template>
                          </div>
                          <span v-if="order.shipping_address.postcode">
                            {{ order.shipping_address.postcode }}
                          </span>
                          <span v-if="order.shipping_address.city"
                            >&nbsp;{{ order.shipping_address.city }}</span
                          >
                          <!-- <span
                            v-if="order.shipping_address.region.region"
                            class="d-block"
                          >
                            {{ order.shipping_address.region.region }}
                          </span> -->
                          <span
                            v-if="order.shipping_address.country_code"
                            class="d-block"
                          >
                            {{ order.shipping_address.country_code }}
                          </span>
                          <span
                            v-if="order.shipping_address.telephone"
                            class="d-block"
                          >
                            {{ order.shipping_address.telephone }}
                          </span>
                        </div>
                        <div v-else>
                          <p v-t="'order.no-billing-address'">
                            No billing address found.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.shipping-method'"
                        >
                          Shipping method
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <template v-if="order.shipping_method">
                          <p>{{ order.shipping_method }}</p>
                        </template>
                        <template v-else>
                          <p v-t="'order.no-shipping-method'">
                            No shipping method found.
                          </p>
                        </template>
                        <!-- <p class="text-md">
                          Carriage paid to
                        </p>
                        <p>
                          Carriage paid to - Shipping costs are calculated after
                          processing.
                        </p> -->
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.billing-address'"
                        >
                          Billing address
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <div v-if="order.billing_address">
                          <div>
                            <span>{{ order.billing_address.firstname }}</span
                            >&nbsp;<span>{{
                              order.billing_address.lastname
                            }}</span
                            ><br />
                          </div>

                          <div v-if="order.billing_address.street">
                            <template
                              v-for="(streetItem, i) in order.billing_address
                                .street"
                              :key="i"
                            >
                              <span class="d-block">{{ streetItem }}</span>
                            </template>
                          </div>
                          <span v-if="order.billing_address.postcode">
                            {{ order.billing_address.postcode }}
                          </span>
                          <span v-if="order.billing_address.city"
                            >&nbsp;{{ order.billing_address.city }}</span
                          >
                          <!-- <span
                            v-if="order.billing_address.region.region"
                            class="d-block"
                          >
                            {{ order.billing_address.region.region }}
                          </span> -->
                          <span
                            v-if="order.billing_address.country_code"
                            class="d-block"
                          >
                            {{ order.billing_address.country_code }}
                          </span>
                          <span
                            v-if="order.billing_address.telephone"
                            class="d-block"
                          >
                            {{ order.billing_address.telephone }}
                          </span>
                        </div>
                        <div v-else>
                          <p v-t="'order.no-shipping-address'">
                            No shipping address found
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.payment-method'"
                        >
                          Payment method
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <template v-if="order.payment_methods">
                          <p
                            class="text-md"
                            v-for="(payment_method, i) in order.payment_methods"
                            :key="i"
                          >
                            {{ payment_method.name }}
                          </p>
                        </template>
                        <template v-else>
                          <p v-t="'order.no-payment-method'">
                            No payment method found.
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="section products list list--products"
            v-if="order.items && order.items.length > 0"
          >
            <div class="section__header d-flex align-items-center mb-3">
              <h4 class="section__title" v-t="'order.items-ordered'">
                Items ordered
              </h4>
            </div>

            <hr class="my-0 mb-3" />

            <div class="section__body">
              <ul class="list-view list-inline">
                <ProductListview4
                  v-for="(product, i) in order.items"
                  :key="i"
                  :product="product"
                ></ProductListview4>
              </ul>
              <div class="row">
                <div class="col-lg-7 offset-lg-5">
                  <div class="box">
                    <div class="box__body bg-white">
                      <div class="box__content p-0">
                        <table class="table table--products w-100 text-sm mt-3">
                          <tbody>
                            <tr>
                              <td v-t="'order.subtotal'">Subtotal</td>
                              <td>
                                <ProductPrice
                                  :price="order.total.subtotal"
                                ></ProductPrice>
                              </td>
                            </tr>
                            <tr>
                              <td v-t="'order.shipping-handling'">
                                Shipping &amp; handling
                              </td>
                              <td>
                                <ProductPrice
                                  :price="
                                    order.total.shipping_handling.total_amount
                                  "
                                ></ProductPrice>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  class="d-inline-block mr-4"
                                  v-t="'order.carriage'"
                                >
                                  Carriage paid to - Shipping costs are
                                  calculated after processing
                                </span>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td v-t="'order.tax'">Tax</td>
                              <td>
                                <ProductPrice
                                  :price="order.total.total_tax"
                                ></ProductPrice>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <div class="double-border"></div>
                              </td>
                            </tr>
                            <tr>
                              <td v-t="'order.request-total'">Request total</td>
                              <td>
                                <ProductPrice
                                  :price="order.total.base_grand_total"
                                ></ProductPrice>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Sidebar from "./Sidebar.vue";
import ProductListview4 from "@/components/Products/ProductListview4.vue";
import ProductPrice from "@/components/Products/ProductPrice.vue";

export default {
  props: {},
  components: {
    Sidebar,
    ProductListview4,
    ProductPrice,
  },
  created() {
    this.retrieveOrder({ search: this.$route.params.orderSlug });
  },
  watch: {
    order: function () {
      if (this.order && this.order.order_number)
        document.title = "Order #" + this.order.order_number + " - VETUS";
    },
  },
  computed: {
    // ...mapGetters(["order"]),
    order: function () {
      if (this.$store.getters.order[this.$route.params.orderSlug]) {
        return this.$store.getters.order[this.$route.params.orderSlug];
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions(["retrieveOrder"]),
  },
};
</script>

<style lang="css" scoped></style>
