<template>
  <section class="section shop">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 order-2 order-lg-1">
          <Sidebar></Sidebar>
        </div>

        <div
          class="col-lg-9 order-1 order-lg-2 mb-6 mb-lg-0"
          v-if="processedOrder"
        >
          <section class="section mb-6">
            <div class="section__header mb-4">
              <div class="d-flex align-items-center">
                <h1 class="section__title mb-0 h3">
                  {{ $t("order.order-hash") }}{{ processedOrder.no }}
                </h1>
                <a
                  href="#"
                  @click="downloadInvoice"
                  class="btn btn-sm btn-empty btn-empty-primary ml-auto"
                  title=""
                >
                  <span v-t="'order.download-invoice'"> Download invoice </span>
                </a>
              </div>
            </div>

            <div class="section__body">
              <div
                class="section__content hentry text-sm"
                v-if="processedOrder.date"
              >
                <!-- <p>
                  <i18n-d
                    tag="p"
                    :value="order.order_date"
                    format="short"
                  ></i18n-d>
                  / {{ order.status }}
                </p> -->
                <!-- <p>{{ order.order_date }} / {{ order.status }}</p> -->
                <p>
                  {{ $d(processedOrder.date, "short") }} /
                  {{ processedOrder.status }}
                </p>
                <p v-if="processedOrder.reference">
                  {{ $t("order.reference") }}
                  <br />
                  <strong class="text-lg">{{
                    processedOrder.reference
                  }}</strong>
                </p>
              </div>
            </div>
          </section>

          <section class="section mb-6">
            <div class="section__header d-flex align-items-center mb-3">
              <h4 class="section__title" v-t="'order.about-order'">
                About this order
              </h4>
            </div>
            <div class="section__body box bg-light">
              <div class="box__content">
                <div class="box-inner">
                  <div class="row row--col-margin-semismall">
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.shipping-address'"
                        >
                          Shipping address
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <div v-if="processedOrder.shippingAddress">
                          <div>
                            <span>{{
                              processedOrder.shippingAddress.contact
                            }}</span
                            >&nbsp;

                            <!-- <span>{{
                              processedOrder.shippingAddress.name
                            }}</span> -->
                            <br />
                          </div>

                          <div v-if="processedOrder.shippingAddress.address">
                            <span class="d-block">{{
                              processedOrder.shippingAddress.address
                            }}</span>
                          </div>

                          <span v-if="processedOrder.shippingAddress.postcode">
                            {{ processedOrder.shippingAddress.postcode }}
                          </span>
                          <span v-if="processedOrder.shippingAddress.city"
                            >&nbsp;{{
                              processedOrder.shippingAddress.city
                            }}</span
                          >
                          <span
                            v-if="processedOrder.shippingAddress.country"
                            class="d-block"
                          >
                            {{ processedOrder.shippingAddress.country }}
                          </span>
                          <span
                            v-if="processedOrder.shippingAddress.telephone"
                            class="d-block"
                          >
                            {{ processedOrder.shippingAddress.telephone }}
                          </span>
                        </div>
                        <div v-else>
                          <p v-t="'order.no-billing-address'">
                            No billing address found.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.shipping-method'"
                        >
                          Shipping method
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <template v-if="processedOrder.shipping_method">
                          <p>{{ processedOrder.shipping_method }}</p>
                        </template>
                        <template v-else>
                          <p v-t="'order.no-shipping-method'">
                            No shipping method found.
                          </p>
                        </template>
                        <!-- <p class="text-md">
                          Carriage paid to
                        </p>
                        <p>
                          Carriage paid to - Shipping costs are calculated after
                          processing.
                        </p> -->
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.billing-address'"
                        >
                          Billing address
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <div v-if="processedOrder.billingAddress">
                          <div>
                            <span>{{ processedOrder.billingAddress.name }}</span
                            >&nbsp;<span>{{
                              processedOrder.billingAddress.lastname
                            }}</span
                            ><br />
                          </div>
                          <div v-if="processedOrder.billingAddress.address">
                            <span class="d-block">{{
                              processedOrder.billingAddress.address
                            }}</span>
                          </div>

                          <span v-if="processedOrder.billingAddress.postcode">
                            {{ processedOrder.billingAddress.postcode }}
                          </span>
                          <span v-if="processedOrder.billingAddress.city"
                            >&nbsp;{{
                              processedOrder.billingAddress.city
                            }}</span
                          >
                          <span
                            v-if="processedOrder.billingAddress.country"
                            class="d-block"
                          >
                            {{ processedOrder.billingAddress.country }}
                          </span>
                          <span
                            v-if="processedOrder.billingAddress.telephone"
                            class="d-block"
                          >
                            {{ processedOrder.billingAddress.telephone }}
                          </span>
                        </div>
                        <div v-else>
                          <p v-t="'order.no-shipping-address'">
                            No shipping address found
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'order.payment-method'"
                        >
                          Payment method
                        </span>
                      </div>
                      <div class="box-inner__content text-sm">
                        <template v-if="processedOrder.payment_methods">
                          <p
                            class="text-md"
                            v-for="(
                              payment_method, i
                            ) in processedOrder.payment_methods"
                            :key="i"
                          >
                            {{ payment_method.name }}
                          </p>
                        </template>
                        <template v-else>
                          <p v-t="'order.no-payment-method'">
                            No payment method found.
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="section products list list--products"
            v-if="processedOrder.items && processedOrder.items.length > 0"
          >
            <div class="section__header d-flex align-items-center mb-3">
              <h4 class="section__title" v-t="'order.items-ordered'">
                Items ordered
              </h4>
            </div>

            <hr class="my-0 mb-3" />

            <div class="section__body">
              <ul class="list-view list-inline">
                <ProductListview5
                  v-for="(product, i) in processedOrder.items"
                  :key="i"
                  :product="product"
                ></ProductListview5>
              </ul>
              <div class="row">
                <div class="col-lg-7 offset-lg-5">
                  <div class="box">
                    <div class="box__body bg-white">
                      <div class="box__content p-0">
                        <table class="table table--products w-100 text-sm mt-3">
                          <tbody>
                            <tr>
                              <td v-t="'order.subtotal'">Subtotal</td>
                              <td>
                                <ProductPrice
                                  :price="processedOrder.price"
                                ></ProductPrice>
                              </td>
                            </tr>
                            <!-- <tr>
                              <td v-t="'processedOrder.shipping-handling'">
                                Shipping &amp; handling
                              </td>
                              <td>
                                <ProductPrice
                                  :price="
                                    processedOrder.total.shipping_handling.total_amount
                                  "
                                ></ProductPrice>
                              </td>
                            </tr> -->
                            <!-- <tr>
                              <td>
                                <span
                                  class="d-inline-block mr-4"
                                  v-t="'order.carriage'"
                                >
                                  Carriage paid to - Shipping costs are
                                  calculated after processing
                                </span>
                              </td>
                              <td></td>
                            </tr> -->
                            <tr>
                              <td v-t="'order.tax'">Tax</td>
                              <td>
                                <ProductPrice
                                  :price="processedOrder.tax"
                                ></ProductPrice>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <div class="double-border"></div>
                              </td>
                            </tr>
                            <tr>
                              <td v-t="'order.request-total'">Total</td>
                              <td>
                                <ProductPrice
                                  :price="processedOrder.priceIncl"
                                ></ProductPrice>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Sidebar from "./Sidebar.vue";
import ProductListview5 from "@/components/Products/ProductListview5.vue";
import ProductPrice from "@/components/Products/ProductPrice.vue";

export default {
  props: {},
  components: {
    Sidebar,
    ProductListview5,
    ProductPrice,
  },
  created() {
    this.retrieveProcessedOrder({ no: this.$route.params.orderSlug });
  },
  watch: {
    processedOrder: function () {
      if (this.processedOrder && this.processedOrder.no)
        document.title = "Order #" + this.processedOrder.no + " - VETUS";
    },
  },
  computed: {
    // ...mapGetters(["order"]),
    processedOrder: function () {
      if (this.$store.getters.processedOrder[this.$route.params.orderSlug]) {
        return this.$store.getters.processedOrder[this.$route.params.orderSlug];
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions([
      "retrieveProcessedOrder",
      "redirectToProcessedOrderInvoice",
    ]),
    downloadInvoice() {
      this.redirectToProcessedOrderInvoice({
        no: this.$route.params.orderSlug,
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
