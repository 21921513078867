<template>
  <!-- Used in Order.vue -->
  <li class="list-item">
    <div class="row">
      <!-- Image does not exist -->
      <div class="col-3 col-sm-2 d-none">
        <div class="list-item__visuals">
          <div class="list-item__media media ratio ratio--square">
            <a href="" class="media__wrapper">
              <!-- <img :src="product.image.url" class="img-fit" alt="" /> -->
            </a>
          </div>
        </div>
      </div>

      <!-- When image available: col-9 col-sm-10 -->
      <div class="col-12">
        <div class="list-item__body">
          <div class="row d-flex">
            <div class="list-item__content product-item__content col-md-6">
              <div class="list-item__title product-item__title">
                <router-link
                  :to="{
                    name: 'ProductSingle',
                    params: {
                      locale: this.$i18n.locale,
                      productSlug: product.product_url_key,
                    },
                  }"
                  title=""
                >
                  <span v-html="product.product_name"></span>
                </router-link>
              </div>
              <div class="list-item__label product-item__sku">
                <span> SKU: {{ product.product_sku }} </span>
              </div>
              <!-- <div class="list-item__desc product-item__desc">

              </div> -->
            </div>
            <div class="product-item__quantity col-md-4">
              <div class="form-group">
                <label for="pw" v-t="'miscellaneous.labels.quantity'"
                  >Quantity</label
                >
                {{ product.quantity_ordered }}
              </div>
            </div>
            <div
              class="product-item__meta col-md-2 d-flex flex-column text-right"
            >
              <ProductPrice
                v-if="isLoggedIn && product.customer_price != null"
                :price="product.product_sale_price"
              ></ProductPrice>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ProductPrice from "./ProductPrice.vue";
export default {
  props: {
    product: {
      type: Object
    }
  },
  components: { ProductPrice }
};
</script>
