<template>
  <!-- Used in Order.vue -->
  <li class="list-item">
    <div class="row">
      <!-- When image available: col-9 col-sm-10 -->
      <div class="col-12">
        <div class="list-item__body">
          <div class="row d-flex">
            <div class="list-item__content product-item__content col-md-6">
              <div class="list-item__title product-item__title">

                 <span> {{ product.description }} </span>
              </div>
              <div class="list-item__label product-item__sku">
                <span> SKU: {{ product.id }} </span>
              </div>

            </div>
            <div class="product-item__quantity col-md-4">
              <div class="form-group">
                <label for="pw" v-t="'miscellaneous.labels.quantity'"
                  >Quantity</label
                >
                {{ product.quantity}}
              </div>
            </div>
            <div
              class="product-item__meta col-md-2 d-flex flex-column text-right"
            >
              <ProductPrice
                v-if="isLoggedIn && product.unitPrice != null"
                :price="product.unitPrice"
              ></ProductPrice>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ProductPrice from "./ProductPrice.vue";
export default {
  props: {
    product: {
      type: Object
    }
  },
  components: { ProductPrice }
};
</script>
